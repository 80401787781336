import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Typography from '@material-ui/core/Typography'
import TextLogo from '../../svg/TextLogo'
import cn from 'clsx'
import { useMediaQuery } from '@material-ui/core'
import Logo from 'svg/logo/IconBoxed'
import { Search } from '@material-ui/icons'
import { landingMobileBreakpoint } from '../../../pages'

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // maxWidth: '1120px',
    gap: theme.spacing(4),
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(8.5),
    padding: '32px 120px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(7),
      gap: theme.spacing(2),
    },
    '@media not all and (min-width:500px)': {
      justifyContent: 'center',
    },
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      padding: '32px 16px',
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
  download: {
    color: theme.palette.primary.main,
  },
  currentPage: {
    marginRight: 'auto',
  },
  tab: {
    color: theme.palette.text.primary,
    position: 'relative',
  },
  smallerOnMobile: {
    '@media not all and (min-width:500px)': {
      fontSize: '1rem',
    },
  },
  wordLogo: {
    color: theme.palette.gray[800],
    width: '56.54px',
    height: '14px',
  },
  selectedLine: {
    position: 'absolute',
    width: '100%',
    height: '1px',
    background: theme.palette.gray[900],
    bottom: '-4px',
  },
  hideOnMobile: {
    '@media not all and (min-width:420px)': {
      display: 'none',
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10.5px',
    marginRight: 'auto',
  },
  iconLogo: {
    width: 13.48,
    height: 14,
    color: theme.palette.gray[800],
  },
  signIn: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  createEvent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.palette.secondary[800],
    cursor: 'pointer',
  },
  addIcon: {
    width: 20,
    height: 20,
  },
  forYou: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  searchIcon: {
    width: 16,
    height: 16,
  },
  smallIcon: {
    width: 18,
    height: 18,
  },
}))

export const HomeHeader = ({}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(500))
  const isVerySmall = useMediaQuery(theme.breakpoints.down(370))
  const classes = useHeaderStyles()
  const { t } = useTranslation('common')
  const router = useRouter()
  const isAbout = router.pathname === '/about'
  const isForYou = router.pathname === '/foryou'

  return (
    <div className={classes.flex}>
      <div className={classes.root}>
        <Link
          href='/'
          passHref
          prefetch={false}
          className={classes.logoContainer}
        >
          <Logo
            className={cn(classes.iconLogo, isSmall && classes.smallIcon)}
          />
          {!isSmall && (
            <TextLogo
              height={14}
              width={56}
              className={cn(classes.currentPage, classes.wordLogo)}
            />
          )}
        </Link>

        <Typography
          className={cn(classes.tab, classes.smallerOnMobile)}
          variant={isSmall ? 'body2' : 'body1'}
        >
          {isForYou && <div className={classes.selectedLine} />}
          <Link href='/foryou' passHref prefetch={false}>
            <div className={classes.forYou}>
              <Search className={classes.searchIcon} />
              Hot events
            </div>
          </Link>
        </Typography>

        <Typography
          className={cn(classes.tab, classes.smallerOnMobile, classes.signIn)}
          variant={isSmall ? 'body2' : 'body1'}
        >
          <Link href='/signIn' passHref prefetch={false}>
            Sign in
          </Link>
        </Typography>
      </div>
    </div>
  )
}

export default HomeHeader
