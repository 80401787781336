import React, { useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { gintoHeaderFontFamily } from '../src/shared-components/theme'
import { useTranslation } from 'react-i18next'
import HomeHeader from '../src/components/Index/HomeHeader'
import { useDownloadApp } from 'shared-components/utils/downloadApp'
import Typography from '@material-ui/core/Typography'
import { Button, useMediaQuery } from '@material-ui/core'
import cn from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import HomeFooter from '../src/components/Index/HomeFooter'
import PagesTopSection from '../src/components/Pages/PagesTopSection'
import { wrapper } from '@redux/store'
import TextLogo from '../src/svg/TextLogo'
import GeneralFooter from '../src/components/event/GeneralFooter'

export const landingMobileBreakpoint = 950
export const landingSmallBreakpoint = 600

export const getStaticProps = wrapper.getStaticProps(
  async ({ store, locale }) => {
    const response = await fetch(
      'https://api.bash.social/organisations/featured-v2?size=20',
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      },
    )
    const results = await response.json()

    return {
      props: {
        pages: results,
      },
      revalidate: 86400,
    }
  },
)

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      'radial-gradient(278.11% 144.33% at 0% 102.81%, rgba(255, 250, 238, 0.60) 7.38%, rgba(228, 234, 255, 0.60) 33%, rgba(242, 230, 251, 0.60) 65%, rgba(253, 237, 225, 0.60) 100%), var(--Core-white, #FFF)',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullVh: {
    width: '100%',
    maxWidth: '1620px',
  },
  mainContainer: {
    display: 'flex',
    marginBottom: 100,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      flexDirection: 'column',
    },
  },
  wordOfMouthContainer: {
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      flexDirection: 'column-reverse',
    },
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  infoContainerLeft: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      justifyContent: 'center',
    },
  },
  infoLeft: {
    marginLeft: '120px !important',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      marginLeft: theme.spacing(2) + 'px !important',
      marginRight: theme.spacing(2) + 'px !important',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 350,
    marginTop: 108,
    maxWidth: 460,
    width: '100%',
    margin: theme.spacing(3),
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      marginTop: 0,
      minWidth: 0,
    },
  },
  header: {
    fontFamily: gintoHeaderFontFamily,
    fontWeight: 800,
    fontSize: '48px',
    lineHeight: '43.2px',
    letterSpacing: '-1px',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      fontSize: '32px',
      lineHeight: '28.8px',
    },
  },
  headerSecondary: {
    color: theme.palette.secondary.main,
  },
  caption: {
    fontWeight: 400,
    marginTop: theme.spacing(2),
    lineHeight: '1.875rem',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      marginTop: theme.spacing(4),
    },
  },
  button: {
    borderRadius: '100px',
    padding: theme.spacing(2, 8),
  },
  createButton: {
    color: 'white',
    background: theme.palette.secondary[800],
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
    width: '100%',
    // maxWidth: 700,
    gap: 20,
    flexShrink: 1,
    alignSelf: 'flex-end',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      marginTop: 50,
      gap: 10,
    },
  },
  wordOfMouthImageContainer: {
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  mainImage: {
    width: '100%',
    minWidth: 483,
    transform: 'translateX(100px)',
    // maxWidth: 613,
    // maxWidth: 613,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      alignSelf: 'flex-end',
      transform: 'none',
    },
    [theme.breakpoints.down(landingSmallBreakpoint)]: {
      alignSelf: 'normal',
    },
  },
  wordOfMouthImage: {
    transform: 'translateX(-30px)',
    alignSelf: 'flex-start',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      alignSelf: 'flex-start',
    },
    [theme.breakpoints.down(landingSmallBreakpoint)]: {
      alignSelf: 'center',
    },
  },
  wordLogo: {
    marginBottom: 16,
    alignSelf: 'flex-start',
  },
}))

const Organisers = ({ pages }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(500))

  const { onDownloadClick, QRCodeModal, downloadUrl } = useDownloadApp({
    utm_medium: 'home',
    usingHref: true,
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage && window.localStorage.setItem('hasSeenBash', 'true')
    }
  }, [])

  return (
    <div className={classes.root}>
      <QRCodeModal />
      <div className={classes.fullVh}>
        <HomeHeader />

        <div className={classes.mainContainer}>
          <div className={cn(classes.infoContainer, classes.infoContainerLeft)}>
            <div className={cn(classes.info, classes.infoLeft)}>
              {isSmall && (
                <TextLogo height={14} width={56} className={classes.wordLogo} />
              )}
              <Typography component='h1' className={classes.header}>
                WHERE <span className={classes.headerSecondary}>HYPE</span>{' '}
                STARTS
              </Typography>
              <Typography
                className={classes.caption}
                component='h4'
                variant='h6'
              >
                Let your events thrive with the power of social interaction. Set
                up your event page, invite friends and start selling tickets.
              </Typography>
              <div className={classes.buttons}>
                <Button
                  href='/create'
                  className={cn(classes.button, classes.createButton)}
                >
                  Create your first event
                </Button>
              </div>
            </div>
          </div>

          <div className={classes.imageContainer}>
            <img
              className={classes.mainImage}
              srcSet='/images/organisers/home_visual_1x.webp 1x, /images/organisers/home_visual_2x.webp 2x'
              alt='visual'
            />
          </div>
        </div>
        <div
          className={cn(classes.mainContainer, classes.wordOfMouthContainer)}
        >
          <div
            className={cn(
              classes.imageContainer,
              classes.wordOfMouthImageContainer,
            )}
          >
            <img
              className={cn(classes.mainImage, classes.wordOfMouthImage)}
              srcSet='/images/organisers/home_visual_wom_1x.webp 1x, /images/organisers/home_visual_wom_2x.webp 2x'
              alt='visual'
            />
          </div>
          <div className={classes.infoContainer}>
            <div className={classes.info}>
              <Typography component='h1' className={classes.header}>
                GO <span className={classes.headerSecondary}>VIRAL</span>
              </Typography>
              <Typography
                className={classes.caption}
                component='h4'
                variant='h6'
              >
                Tap into your guests' social circles to spread the hype. Share
                the link, and grow your audience organically.
              </Typography>
              <div className={classes.buttons}>
                <Button
                  href='/create'
                  className={cn(classes.button, classes.createButton)}
                >
                  Create your first event
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PagesTopSection pages={pages} />

      <GeneralFooter maxWidth={'1620px'} />
    </div>
  )
}

// Var used to disable some stuff applied to all pages through _app.js
Organisers.trimApp = true

export default Organisers
