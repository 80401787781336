import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Logo from 'svg/logo/IconBoxed'
import TextLogo from '../../svg/TextLogo'
import { Button, SvgIcon } from '@material-ui/core'
import { useDownloadApp } from '../../shared-components/utils/downloadApp'
import { mdiInstagram, mdiLinkedin, mdiWhatsapp } from '@mdi/js'
import Link from 'next/link'
import GeneralFooter from '../event/GeneralFooter'

const useFooterStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.palette.secondary[950],
    color: 'white',
    padding: theme.spacing(6, 10),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      marginTop: 200,
      gap: theme.spacing(3),
      justifyContent: 'center',
      padding: theme.spacing(6, 7),
      '& > *': {
        width: '100%',
        alignItems: 'center',
        position: 'relative !important',
        textAlign: 'center',
      },
    },
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: 34.16,
    height: 35.49,
  },
  textLogo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  middle: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  getAppButton: {
    borderRadius: '100px',
    padding: theme.spacing(2, 3),
    color: 'white',
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary[800],
    },
  },
  socialsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
  },
  right: {
    display: 'flex',
    gap: 54,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
      textAlign: 'center',
    },
  },
  socialsIcon: {
    width: 24,
    height: 24,
    color: 'white',
  },
  tiktokIcon: {
    width: 20,
    height: 20,
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    textAlign: 'right',
    '& *': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '100%',
      textAlign: 'center',
      '& br': {
        display: 'none',
      },
    },
  },
}))

const HomeFooter = ({ color, route = '/' }) => {
  const classes = useFooterStyles()
  const { t } = useTranslation('common')

  const { onDownloadClick, QRCodeModal, downloadUrl } = useDownloadApp({
    utm_medium: 'home',
    usingHref: true,
  })

  return <GeneralFooter maxWidth={'1620px'} />

  return (
    <div className={classes.root}>
      <QRCodeModal />
      <div className={classes.left}>
        <Logo className={classes.logo} />
        <TextLogo className={classes.textLogo} />
        <Typography variant='caption'>
          © BASH copyright and trademark 2024
        </Typography>
      </div>

      <div className={classes.middle}>
        <Button
          onClick={onDownloadClick}
          href={downloadUrl}
          className={classes.getAppButton}
        >
          Get the app
        </Button>

        <div className={classes.socialsContainer}>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.instagram.com/bash.social/'
          >
            <SvgIcon className={classes.socialsIcon}>
              <path d={mdiInstagram} />
            </SvgIcon>
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.tiktok.com/@bash.social'
          >
            <img
              color='white'
              src='/images/tiktok.svg'
              className={classes.tiktokIcon}
            />
          </a>

          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://linkedin.com/company/bashsocial'
          >
            <SvgIcon className={classes.socialsIcon}>
              <path d={mdiLinkedin} />
            </SvgIcon>
          </a>

          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://wa.me/message/4GG2EGM4GIDBD1'
          >
            <SvgIcon className={classes.socialsIcon}>
              <path d={mdiWhatsapp} />
            </SvgIcon>
          </a>
        </div>
      </div>

      <div className={classes.right}>
        <div className={classes.rightColumn}>
          <Link href='/' passHref>
            <Typography variant='caption'>Home</Typography>
          </Link>

          <Link href='/about' passHref>
            <Typography variant='caption'>About</Typography>
          </Link>

          <Link href='/pricing' passHref>
            <Typography variant='caption'>Pricing</Typography>
          </Link>
        </div>

        <div className={classes.rightColumn}>
          <Link href='/jobs'>
            <Typography variant='caption'>Jobs</Typography>
          </Link>

          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://wa.me/message/4GG2EGM4GIDBD1'
          >
            <Typography variant='caption'>Support</Typography>
          </a>

          <Link href='/privacy' passHref>
            <Typography variant='caption'>Privacy</Typography>
          </Link>

          <Link href='/terms' passHref>
            <Typography variant='caption'>
              Terms & <br />
              conditions
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeFooter
